import React from "react";
import { useRef } from "react";
import './Join.css';
import emailjs from '@emailjs/browser';

export default function Join(){
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_09fal4f', 'template_qkl3syu', form.current, 'kV3UPmJezJw6Fx60q')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        };
    return(
        <div className="Join" id="join-us">
            <div className="left-j">
                <hr />
                <div>
                    <span className="stroke-text">READY TO </span>
                    <span>LEVEL UP</span>
                </div>
                <div>
                    <span>YOUR BODY </span>
                    <span className="stroke-text">WHITH US?</span>
                </div>
            </div>
            <div className="right-j">
                <form ref={form} className="email-container" onSubmit={sendEmail}>
                    <input type="email" name="user_email" placeholder="Enter your Email adresse" />
                    <button className="btn btn-j" type="submit">Join now</button>
                </form>
            </div>
        </div>
    )
}